<template>
  <v-row>
    <v-col
      cols="12"
    >
      <div
        class="d-flex justify-center align-center"
      >
        <v-icon
          left
          color="primary"
          size="75"
        >
          mdi-face-agent
        </v-icon>
        <span
          class="text-overline primary--text"
        >
          Central de Atendimento
        </span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data: () => ({
    }),
  }
</script>
